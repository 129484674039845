import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { UserBiometric, PersonalDetails, MultiStepForm, PersonalProfile, PeoplePage, AdminTeamCreationForm, AdminTeamMain, PeopleInactiveUsers} from '../../components';
import { connect } from 'react-redux';
import { fetchCompanyTeamCount } from '../../redux/actions';

class People extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: props.companyInfo['id']
    };
  }

  componentDidMount() {
    const { fetchCompanyTeamCount } = this.props;
    const { companyId } = this.state;
    fetchCompanyTeamCount(companyId);
  }

  multiStepForm = () => {
    const { history } = this.props;
    return (<MultiStepForm history={history} />)
  }

  userBiometric = () => {
    const { history } = this.props;
    return (<UserBiometric history={history} />)
  }

  personalDetails = () => {
    const { history } = this.props;
    return (<PersonalDetails history={history} />)
  }

  peopleHome = () => {
    const { companies, companyInfo, setCompany, role, history } = this.props;
    return (<PeoplePage companies={companies} companyInfo={companyInfo} setCompany={setCompany} role={role} history={history} showView={true}/>)
  }

  inactiveUsers = () => {
    const { companies, companyInfo, setCompany, role, history } = this.props;
    return (<PeopleInactiveUsers companies={companies} companyInfo={companyInfo} setCompany={setCompany} role={role} history={history} showView={true}/>)
  }

  teamCreation = () => {
    const { history, companyInfo, employeeCount, teamsCount } = this.props;
    return (<AdminTeamCreationForm history={history} companyInfo={companyInfo} employeeCount={employeeCount} teamsCount={teamsCount}/>)
  }

  teamPage = () => {
    const { history, companyInfo, teamsCount,role } = this.props;
    return (<AdminTeamMain history={history} companyInfo={companyInfo} teamsCount={teamsCount} role={role}/>)
  }

  personalProfile = () => {
    const { history, companyInfo, teamsCount,role } = this.props;
    return (<PersonalProfile history={history} companyInfo={companyInfo} teamsCount={teamsCount} role={role}/>)
  }

  render() {
    return (
      <Switch>
        <Route path="/company/people"
          exact={true} render={this.peopleHome}
        />
        <Route path="/company/people/inactive-users"
          exact={true} render={this.inactiveUsers}
        />
        <Route path="/company/people/team"
          exact={true} render={this.teamPage}
        />
        <Route path="/company/people/team-creation"
          exact={true} render={this.teamCreation}
        />
        <Route path="/company/people/:id/multi-step/physician-form" render={this.multiStepForm} />
        <Route path="/company/people/:id/biometric/step1" render={this.userBiometric} />
        <Route path="/company/people/:id/biometric/step2" render={this.personalDetails} />
        <Route path="/company/people/:id/personal-profile" render={this.personalProfile} exact={true}/>
      </Switch>
    )
  }
}

AdminTeamMain.defaultProps = {
  companyInfo: { id: 1, name: '' }
};

People.propTypes = {
  companies: PropTypes.array,
  companyInfo: PropTypes.object,
  setCompany: PropTypes.func,
  role: PropTypes.string,
  history: PropTypes.object,
  fetchCompanyTeamCount: PropTypes.func,
  teamsCount: PropTypes.array,
  employeeCount: PropTypes.number
}

const mapStateToProps = (state) => ({
  teamsCount: state.adminTeamCreation.teamsCount,
  employeeCount: state.companyDashboard.employeeCount
});

const mapDispatchToProps = (dispatch) => ({
  fetchCompanyTeamCount: (companyId) => dispatch(fetchCompanyTeamCount(companyId))
});

export default connect(mapStateToProps, mapDispatchToProps)(People);