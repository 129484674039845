import styled, { css } from "styled-components";

const BoldFont = 'Rubik-Bold';

const Layout = styled.div`
  float: ${({float}) => float ? '' : 'left'};
  width: 100%;
  background-color: ${({background,fullwidth}) =>fullwidth? "#f3f6f9": background ? '#fff' : '#f7f7f7'};
  border-top: ${({border}) => border ? 'none' : '10px solid #159fc9'};
  min-height: 100vh;
  display: ${({display}) => display ? 'flex' : ''};
  @media only screen and (max-width: 600px) {
    flex-wrap:wrap;
    flex-direction:column
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin 0 auto;
`;

const TitleContainer = styled.div`
  text-align: center;
  width: 100%;
  margin-top: 8vmin;
  
  > img {
    width: 13%;
    height: ${({height}) => height ? '50px' : '100px'};
    min-width: 150px;
    cursor: pointer;
  }
`;

const Title = styled.div`
  font-size: 14px;
  letter-spacing: 0px;
  color: rgb(51,51,51);
  font-family: ${BoldFont};
  line-height: 30px;
  text-transform: uppercase;
  cursor: default;
`;

const ContactUsSection = styled.div`
  float: left;
  width: 100%;
  text-align: center;
  margin-top: 9vmin;
  
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 30px;
  color: rgb(51,51,51);
  text-align: center;
  
  > span {
    font-family: ${BoldFont};
    text-transform: uppercase;
  }
  
  > div {
    color: rgb(153,153,153);
  }
  > a {
    color: rgb(21,159,202);
    text-decoration: underline;
  }
`;

const FooterText = styled.div`
  width: 100%;
  float: left;
  margin-top: 15vmin;
  font-size: 12px;
  letter-spacing: 0px;
  color: rgb(153,153,153);
  text-align: center;
  margin-bottom: 20px;
`;

const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  position: absolute;
  right: 5px;
`;

const ImageWrapper = styled.div`
  width: 36.5%;
  background: linear-gradient(#102B52 22.92%, #006FBC 100%);
  .text{
    font-family: 'Rubik-Bold';
    font-size: 48px;
    line-height: 59px;
    color:#FFFFFF;
    margin: 70px auto 0px auto;
    text-align: center;
    position: fixed;
    width: 36.5%;
  }
  .image{
    bottom: 0;
    position: fixed;
    width: 36.5%;
    >img{
      width: 100%;
      height: auto;
    }
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
    height:85%;
    margin-top:75px;
    .text{width: 90%;
      font-size: 36px;
      line-height: 44px;
      margin: 25px auto 15px auto;
      position: static
    }
    .image{position: static;
      width:100%;
      padding:0px 25px 0px 25px;
    }
  }
display:${({display}) => display ? 'block' : 'none'};


`;

const Formwrapper = styled.div`
 width: ${({fullwidth}) => fullwidth ? '100%' : '63.5%'};
 background:  ${({fullwidth}) => fullwidth ?  ' #f3f6f9 ':"white "};
 flex-wrap: wrap;
 min-height:${({fullwidth}) => fullwidth ? "unset":"902px"};
 .logo{
  padding: ${({fullwidth}) => fullwidth ?"20px 40px":""};
   height: ${({fullwidth}) => fullwidth ? '80px' : '60px'};
   background-color: ${({fullwidth}) => fullwidth ? 'white' : 'none'};
   z-index:3;
   box-shadow:  ${({fullwidth}) => fullwidth ? `0px 11px 14px rgba(0, 0, 0, 0.1)`:"none"};
   display: flex;
   width: ${({fullwidth}) => fullwidth ? '100%' : '500px'};
   position: ${({fullwidth}) => fullwidth ? 'fixed' : 'static'};
   top:0;
   display: flex;
   margin: auto;
   margin-top: ${({fullwidth}) => fullwidth ? "0": '105px'};
   margin-bottom: 72px;
   justify-content: flex-start;
   >img{
     height: ${({fullwidth}) => fullwidth ?"40px":"60px"};
     width: auto;
   }
 }
 @media only screen and (max-width: 600px) {
  width:100%;
  min-height:unset;
  height:100%;
  .logo{
    height: 62px;
    padding:0;
    box-shadow: none;
    display: flex;
    width: 100%;
    display: flex;
    margin: auto;
    position:absolute;
    top:0;
    justify-content: flex-start;
    >img{
      margin:20px 0px 25px 20px;
      height: 30px;
      width: 120px;
    }
  }
}
`;

const BottomWrapper = styled.div`
  width: ${({fullwidth}) => fullwidth ? '100%' : '63.5%'};
  display: flex;
  height: 65px;
  background: rgba(249, 249, 249, 1);
  justify-content: space-between;
   margin-top:${({fullwidth}) => fullwidth ? "0":" 13%"};
  overflow: hidden;
  position:${({fullwidth}) => fullwidth ? "absolute":"fixed"};
  bottom: 0;
  .left{
    display: flex;
    justify-content: flex-start;
  .text{
    font-family: Rubik;
    font-size: 14px;
    line-height: 15.5px;
    color:#9C9C9C;
    margin-left: 25px;
    align-items: center;
    display: flex;
    cursor: pointer;
  }
}
  .copyRight{
    display: flex;
    justify-content: flex-end;
    .dark{
      font-family: Rubik;
      font-size: 18px;
      line-height: 24px;
      color: #0D4270;
      margin-right: 25px;
      align-items: center;
      display: flex;
      cursor: pointe
    }
    .text{
      font-family: Rubik;
      font-size: 16px;
      line-height: 15.5px;
      color:#9C9C9C;
      margin-left: 25px;
      align-items: center;
      display: flex;
      cursor: pointer;
      padding-top: 3px;
    }
  }

  @media only screen and (max-width: 600px) {
    display:none;
    position:static;
    width:100%;
    height:auto;
    padding-bottom:10px;
    justify-content:center;
    flex-direction:column;
  .left{
    justify-content:space-evenly;
    margin:20px 0px;
  .text{
    margin:0
  }
}
.copyRight{
  justify-content:center;
  margin:0;
 .dark{
   margin:0; font-size:13px;
  }
.text{
  margin:0 8px 0 0;
  font-size:13px;}
}
  }
`;

const Languagecontainer = styled.div`
justify-content: flex-end;
display: flex;
align-items:center;
position:${({fullwidth})=>fullwidth? "fixed":"absolute"} ;
right: 40px;
width:50px;
margin: auto;
margin-top: 0px;

top:${({fullwidth})=>fullwidth? "25px":"2%"} ;
z-index:5;


h3,h2{
font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
display: flex;
align-items: center;
margin:0;
color: #9C9C9C;
}
h2{
  display:none
}

  > ul {
    width: 170px;
    list-style: none;
    display: ${({drop})=>drop ? "block":"none"};
    position: absolute;
    top: 40px;
    background-color: white;
    font-family: ${BoldFont};
    padding: 0;
    z-index: 1;
    border-radius: 5px;
    box-shadow: 1px 1.732px 46px rgba(0,0,0,0.18);
    

    > li {
      text-align: center;
      padding: 5px 0;
      > a {
        width: 100%;
        display: block;
        padding: 5px 25px;
        color: #67676A;
        cursor: pointer;
        &:hover {
          text-decoration: none;
          color: white;
        }
        span{
          div{
            > img {
          width: 19px;
          height: 19px;
          float: left;
          margin-right: 5px;
        }
          }
        }
        > img {
          width: 19px;
          height: 19px;
          float: left;
          margin-right: 5px;
        }
      }
    }
  }
 
  ul > li:hover {
    color: white;
    background-color: ${({color}) => color || "#3d4d67"};
  }



  @media (max-width: 600px) {
    h3{
      display:none
    }
    h2{
      display:block
    }
    position: absolute;
    right: 0;
    margin-right:5px;
    z-index:5;
    }

`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content:space-between;
  width:60px;
  height:30px;
  margin-left:15px;
  font-family: ${BoldFont};
  // padding: ${({padding}) => (padding) ? padding :'0 20px;'};
  padding: 4px;
  cursor: pointer;
  position: relative;
  //position: fixed;
  border: 1px solid rgba(156, 156, 156, 0.4);
border-radius: 3px;
  > .notification-bell:hover g path {
        fill: ${({color}) => color || "#3d4d67"};
   }

   .arrowContainer {
    width:14px;
    height:14px
  margin-left:10px;
  }

  .arrowContainer .arrow{
vertical-align:unset;
  
  }




    .lang {
    height: 22px;
    width: 22px;
    
   }
  span{
    position: absolute;
    width: 9px;
    height: 9px;
    background: #fd7175;
    border-radius: 50%;
    left: 30px;
    top: -1px;
  }
  @media (max-width: 500px){
    span{
      top: 16px;
      left: 19px;
    }
  }
  ${({ active }) => active && css`
    > svg {
      fill: ${({color}) => color || "#3d4d67"};
    }
  `}
`;

export { Layout, ContactUsSection, FooterText, ContentWrapper, Title, TitleContainer, StyledButton, ImageWrapper, Formwrapper, BottomWrapper, Languagecontainer, IconContainer };
